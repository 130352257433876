<template>
    <div class="card card-default">
        <div class="row">
            <div class="col-md-12">
                <form-wizard
                    title=""
                    subtitle=""
                    stepSize="md"
                    color="#3a3f51"
                    errorColor="none"
                    @on-complete="saveProduct()"
                    @on-validate="saveProductDetails(newProduct)"
                >
                    <tab-content
                        title="Details"
                        icon="fa-solid fa-circle-info"
                        :before-change="()=>validateStep('details')"
                    >
                        <form data-vv-scope="details">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h4>Product Information</h4>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="form-group col-md-4">
                                            <label>Product Number</label>
                                            <input type="text" class="form-control" v-model="newProduct.productNumber" disabled>
                                        </div>
                                        <div class="form-group col-md-8">
                                            <label>Product Description</label>
                                            <input type="text" class="form-control" v-model="newProduct.description">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-4">
                                            <label>For Sale</label>
                                            <select v-model="newProduct.forSale" class="form-control">
                                                <option :value="true">Yes</option>
                                                <option :value="false">No</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label>Product Restrictions</label>
                                            <select class="form-control" v-model="newProduct.clientAccessRestriction">
                                                <option
                                                    :value="cRestriction.description"
                                                    v-for="cRestriction in productRestrictions"
                                                    :key="cRestriction.name"
                                                >
                                                    {{ cRestriction.description }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label>Wizard Restrictions</label>
                                            <select class="form-control" v-model="newProduct.wizardAccessRestriction">
                                                <option
                                                    :value="wRestriction.description"
                                                    v-for="wRestriction in wizardRestrictions"
                                                    :key="wRestriction.name"
                                                >
                                                    {{ wRestriction.description }}
                                                </option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h4>Product Classification</h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="form-group col-md-4">
                                            <label>Type</label>
                                            <select
                                                class="form-control"
                                                v-model="newProduct.classificationType"
                                                @change="getProductSuppliers()"
                                            >
                                                <option
                                                    v-for="classType in productClassificationTypes"
                                                    :value="classType"
                                                    :key="classType"
                                                >
                                                    {{ classType }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label>Supplier</label>
                                            <select
                                                class="form-control"
                                                v-model="newProduct.supplierNumber"
                                                @change="getProductVendors()"
                                            >
                                                <option
                                                    :value="supplier.supplierNumber"
                                                    v-for="supplier in suppliers"
                                                    :key="supplier.supplierNumber"
                                                >
                                                    {{ supplier.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label>Vendor</label>
                                            <select
                                                class="form-control"
                                                v-model="newProduct.vendorNumber"
                                                @change="getProductSegments()"
                                            >
                                                <option
                                                    :value="vendor.vendorNumber"
                                                    v-for="vendor in vendors"
                                                    :key="vendor.vendorNumber"
                                                >
                                                    {{ vendor.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-4">
                                            <label>Segment</label>
                                            <select
                                                class="form-control"
                                                v-model="newProduct.segmentNumber"
                                                @change="getSubSegmentsForSegment(newProduct.segmentNumber)"
                                            >
                                                <option
                                                    :value="segment.segmentNumber"
                                                    v-for="segment in segments"
                                                    :key="segment.segmentNumber"
                                                >
                                                    {{ segment.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label>Subsegment</label>
                                            <select
                                                class="form-control"
                                                v-model="newProduct.subSegmentNumber"
                                            >
                                                <option
                                                    :value="subSegment.subSegmentNumber"
                                                    v-for="subSegment in subSegments"
                                                    :key="subSegment.subSegmentNumber"
                                                >
                                                    {{ subSegment.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-4">
                                                <label>Sale Definition</label>
                                                <select
                                                    class="form-control"
                                                    v-model="newProduct.productDefinition"
                                                >
                                                    <option
                                                        v-for="def in saleDefinitions"
                                                        :value="def"
                                                        :key="def"
                                                        >
                                                        {{ def }}
                                                    </option>
                                                </select>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </tab-content>
                    <tab-content
                        title="Configuration"
                        icon="fa-solid fa-gears"
                    >
                        <div data-vv-scope="configuration">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row row-spacing">
                                        <div class="col-md-12">
                                            <h4>Product Variables</h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="table-responsive table-bordered">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Description</th>
                                                            <th>Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-if="newProduct.productvariables.length > 0">
                                                        <tr
                                                            v-for="(variable, index) in newProduct.productvariables"
                                                            :key="index"
                                                            :class="{'product-var-removed': variable.removed,'product-var-added': variable.added,}">
                                                            <td>
                                                                <i class="fa fa-minus-circle" title="Product var not in Sale Def anymore" v-if="variable.removed"></i>
                                                                <i class="fa fa-plus-circle" title="Product var added to Sale Def and this product needs a value" v-if="variable.added"></i>
                                                                <span class="ml-2">{{ variable.description }}:</span>
                                                            </td>
                                                            <td>
                                                                <span class="badge" :class="getProductVarTypeClass(variable.type)" style="margin-bottom: 5px">
                                                                    {{ variable.type }}
                                                                </span>
                                                                <input v-model="variable.value" class="form-control col-md-3"/>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <tr>
                                                            <td colspan="2">No variables available for this product</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab-content>
                </form-wizard>
            </div>
        </div>
    </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import "vue-form-wizard/dist/vue-form-wizard.min.css";

    export default {
        components: {
            FormWizard,
            TabContent
        },
        data() {
            return {
                productNumber: 0,
                newProduct: {
                    productvariables: []
                },
                productRestrictions: [],
                wizardRestrictions: [],
                productClassificationTypes: [],
                suppliers: [],
                vendors: [],
                segments: [],
                subSegments: [],
                saleDefinitions: [],
                inititalProductVariables: [],
            }
        },
        created() {
            this.getProductClassificationTypes();
        },
        mounted() {
            this.getProductRestrictions();
            this.getWizardRestrictions();
            this.getSaleDefinitions();
        },
        methods: {
            validateStep: function(stepName) {
                return this.$validator.validateAll(stepName).then(result => {
                    if (result) {
                        return true;
                    } else {
                        return false;
                    }
                });
            },
            getExistingProduct: function(productNumber) {
                const hostUrl = this.$config.aimsAPI;

                this.$http.get(`${hostUrl}products/${productNumber}`).then(
                    (response) => {
                        this.newProduct = response.data;

                        const productDefinitionMetaData = this.getProductDefinition(this.newProduct.productDefinition);
                        this.inititalProductVariables = this.deepCopyObject(this.newProduct.productvariables);
                        this.getProductVariables();
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Fetching Product", error);
                    }
                );
            },
            saveProductDetails: function(newProduct) {
                const hostUrl = this.$config.aimsAPI;
                const productCopy = this.deepCopyObject(newProduct);

                const productObj = {
                    product: productCopy,
                    productVariables: productCopy.productvariables,
                };

                productObj.product.class = "adept.aims.classes.Product";

                productObj.product.prorataType = "FULL_PRICE";
                // productObj.product.auditType = "ALWAYS";
                this.$http.post(`${hostUrl}products/save`, productObj).then(
                    (response) => {
                        this.newProduct = response.data.product;
                        this.newProduct.productvariables = response.data.productVariables;
                        this.showSuccess("Save Success");
                        this.getExistingProduct(this.newProduct.productNumber);
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Saving Product", error);
                    }
                );
            },
            saveProduct: function() {
                const hostUrl = this.$config.aimsAPI;
                const productCopy = this.deepCopyObject(this.newProduct);

                const productObj = {
                    product: productCopy,
                    productVariables: productCopy.productvariables,
                };                

                this.$http.post(`${hostUrl}products/save`, productObj).then(
                    (response) => {
                        this.showSuccess("Save Success");
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Saving Product", error);
                    }
                ).finally(() => {
                    this.$router.push({
                        name: "productdetail",
                        params: {
                            productNumber: this.newProduct.productNumber,
                        },
                    });
                })
            },
            getProductRestrictions: function() {
                const hostUrl = this.$config.aimsAPI;

                this.$http.get(`${hostUrl}products/restrictions`).then(
                    (response) => {
                        this.productRestrictions = response.data;
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error fetching product restrictions", error);
                    }
                );
            },
            getWizardRestrictions: function() {
                const hostUrl = this.$config.aimsAPI;
                this.$http.get(`${hostUrl}products/restrictions/wizard`).then(
                    (response) => {
                        this.wizardRestrictions = response.data;
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error fetching product restrictions", error);
                    }
                );
            },
            getProductClassificationTypes: function() {
                const hostUrl = this.$config.aimsAPI;

                this.$http.get(`${hostUrl}products/classifications/types`).then(
                    (response) => {
                        this.productClassificationTypes = response.data;
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error fetch product classification types", error);
                    }
                );
            },
            getProductSuppliers: function() {
                const hostUrl = this.$config.aimsAPIv2;

                this.$http.get(`${hostUrl}suppliers`).then(
                    (response) => {      
                        this.suppliers = response.data;               
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error fetching product suppliers", error);
                    }
                );
            },
            getProductVendors: function() {
                const hostUrl = this.$config.aimsAPIv2;

                this.$http.get(`${hostUrl}vendors`).then(
                    (response) => {
                        this.vendors = response.data;                                      
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error fetching product vendors", error);
                    }
                );
            },
            getProductSegments: function() {
                const hostUrl = this.$config.aimsAPIv2;

                this.$http.get(`${hostUrl}segments`).then(
                    (response) => {      
                        this.segments = response.data;            
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error fetching product vendors", error);
                    }
                );
            },
            getSubSegmentsForSegment: function(segmentNumber) {
                const hostUrl = this.$config.aimsAPIv2;

                this.$http.get(`${hostUrl}subsegments/segment/${segmentNumber}`).then(
                    (response) => {
                        this.subSegments = response.data;
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error fetching product subsegments", error);
                    }
                );
            },
            getSaleDefinitions: function() {
                const hostUrl = this.$config.aimsAPI;
                const productType = this.newProduct.definitionType;

                if (productType === "Old") {
                    this.$http.get(`${hostUrl}sales/definition/available/legacy`).then(
                        (response) => {
                            this.saleDefinitions = response.data;
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error fetching legacy sale definitions", error);
                        }
                    );
                } else {
                    this.$http.get(`${hostUrl}sales/definition/available`).then(
                        (response) => {
                            this.saleDefinitions = response.data;
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error fetching legacy sale definitions", error);
                        }
                    );
                }
            },
            getProductVariables: function () {
                const hostUrl = this.$config.aimsAPI;
                const productNumber = this.newProduct.productNumber;

                this.$http.get(`${hostUrl}sales/definition/vars/${productNumber}`).then(
                    (response) => {
                            const currentProductVarsForSaleDef = response.data;

                            currentProductVarsForSaleDef.map((cpv) => {
                                const index = this.inititalProductVariables.findIndex((ipv) =>
                                    ipv.name === cpv.name
                                );

                                if (index === -1) {
                                    const toAdd = this.deepCopyObject(cpv);
                                    toAdd.added = true;
                                    this.newProduct.productvariables.push(toAdd);
                                }
                            });

                            this.newProduct.productvariables.map((ipv) => {
                                const index = currentProductVarsForSaleDef.findIndex(
                                    (cpv) => cpv.name === ipv.name
                                );

                                if (index == -1) {
                                    ipv.removed = true;
                                }
                            });
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Fetching Product Variables", error);
                    }
                );
            },
            getProductVarTypeClass: function(type) {
                switch (type) {
                case "STRING":
                    return "bg-green";
                case "DOUBLE":
                    return "bg-warning";
                case "INTEGER":
                    return "bg-primary";
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.product-var-removed {
    color: red;
}

.product-var-added {
color: green;
}
</style>
