<template>
  <div>
    <div class="row row-spacing">
      <div class="col-md-4">
        <label>Search Services</label>
        <div class="input-group">
          <input
            type="search"
            class="form-control"
            placeholder="Search by Product Description or Sale Tag..."
            v-model="serviceStore.searchTerm"
          />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2"
              ><i class="fa fa-search"></i
            ></span>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <label>Filter by Category</label>
        <div class="input-group">
          <v-select
            :options="segments"
            label="name"
            valueProp="name"
            v-model="serviceStore.selectedSegment.name"
            :clearable="true"
            placeholder="Select Category"
          >
          </v-select>
        </div>
      </div>
      <div class="col-md-3">
        <label>Filter by Product</label>
        <div class="input-group">
          <v-select
            :options="clientProducts"
            label="description"
            valueProp="description"
            v-model="serviceStore.selectedProduct.description"
            :clearable="true"
            placeholder="Select Product"
            :searchable="true"
          >
          </v-select>
        </div>
      </div>
    </div>
    <hr />
    <div class="col-md-12">
      <div class="row row-spacing border py-3 services-container">
        <div class="col-md-12">
          <div class="">
            <div v-if="filteredServices.length > 0 && servicesLoaded === true">
              <div
                class="card border-dark-grey"
                v-for="service in filteredServices"
                :key="service.productNumber"
              >
                <div
                  class="card-header d-flex align-items-center justify-content-between"
                >
                  <div class="service-name d-flex align-items-center">
                    <h4 class="mr-2 mb-0">{{ service.description }}</h4>
                  </div>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <tbody v-if="service.sales.length > 0">
                        <tr
                          v-for="sale in service.sales"
                          :key="sale.saleNumber"
                          @click="viewSale(service, sale)"
                        >
                          <td>
                            {{ sale.description
                            }}<span
                              class="ml-2"
                              :class="showSaleStatusBadge(sale.status)"
                              >{{ showSaleStatus(sale.status) }}</span
                            >
                          </td>
                          <td style="text-align: right">
                            <div
                              class="sale-tag badge mx-1 text-white"
                              v-for="tags in sale.saletagvalues"
                              :key="tags.saleTagValueNumber"
                              v-tooltip="`${tags.saletag.name}`"
                              :style="`background: ${tags.saletag.colour}`"
                            >
                              <i class="fa fa-tag" aria-hidden="true"></i>
                              <span class="ml-1">{{ tags.value }}</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="servicesLoaded === false" style="max-height: 300px">
              <DataLoader
                :loadingText="'Loading Services...'"
                style="height: 100%"
              />
            </div>
            <div v-else>
              <div class="card m-0 border-dark-grey">
                <div class="card-header d-flex align-items-center justify-content-between">
                  <h4 class="m-0">No Services Found</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useServicesStore } from "@/stores/pages/Client/Services.ts";

import DataLoader from "@/components/GeneralComponents/DataLoader";

export default {
  components: {
    DataLoader,
  },
  data() {
    const serviceStore = useServicesStore();
    return {
      serviceStore,
      clientNumber: null,
      services: [],
      servicesLoaded: false,
      filteredServicesList: [],
      productOptions: [],
      product: null,
      icon: "",
      saleNumber: null,
      segments: [],
      clientProducts: [],
    };
  },
  async created() {
    this.$root.$emit("SET_CLIENT_PAGE_TITLE", "Services");
    this.clientNumber = this.$route.params.clientNumber;
    await this.getProductSegments();
    await this.getServicesForClient();
  },
  mounted() {
    if (Object.keys(this.$route.query).includes("name")) {
      this.serviceStore.selectedSegment.name = this.$route.query.name;
    }
  },
  computed: {
    filteredServices() {
      let filteredServicesList = [...this.services];

      // Apply search term filter
      if (this.serviceStore.searchTerm) {
        const searchTerm = this.serviceStore.searchTerm.toLowerCase();
        filteredServicesList = filteredServicesList.map(service => {
            const matchingSales = service.sales.filter(sale =>
              sale.description.toLowerCase().includes(searchTerm) ||
              this.isProductMatchingSearch(sale, searchTerm) ||
              sale.saletagvalues.some(saleTag =>
                saleTag.value.toLowerCase().includes(searchTerm)
              )
            );

            return { ...service, sales: matchingSales };
          })
          .filter(service => service.sales.length > 0);
      }

      // Apply segment filter
      if (this.serviceStore.selectedSegment.name) {
        this.servicesLoaded = false;
        const selectedSegment = this.normalizeString(this.serviceStore.selectedSegment.name);
        const segment = this.segments.find(s => this.normalizeString(s.name) === selectedSegment);

        if (segment) {
          filteredServicesList = filteredServicesList.filter(
            service => service.segmentNumber === segment.segmentNumber
          );
          this.servicesLoaded = true;
        }
      }

      // Apply product filter
      if (this.serviceStore.selectedProduct.description) {
        const selectedProduct = this.normalizeString(this.serviceStore.selectedProduct.description);
        const product = this.clientProducts.find(p => this.normalizeString(p.description) === selectedProduct);

        if (product) {
          filteredServicesList = filteredServicesList.filter(
            service => service.productNumber === product.productNumber
          );
        }
      }

      return filteredServicesList;
    }
  },
  methods: {
    getServicesForClient: function () {
      const hostUrl = this.$config.aimsAPI;
      const clientNumber = this.clientNumber;

      this.$http.get(`${hostUrl}sales/client/${clientNumber}/services`).then(
        (response) => {
          this.services = response.data;
          this.servicesLoaded = true;
          let productNumbers = [];

          this.services.forEach((service) => {
            productNumbers.push(service.productNumber);
          });

          productNumbers = [...new Set(productNumbers)];

          this.getClientProducts(productNumbers);
        },
        (error) => {
          this.showError("Error fetching Active Sales for Client", error);
          console.error(error);
        }
      );
    },
    getClientProducts: function (productNumbers) {
      const hostUrl = this.$config.aimsAPI;

      this.$http.post(`${hostUrl}products/clients`, productNumbers).then(
        (response) => {
          this.clientProducts = response.data;
        },
        (error) => {
          this.showError("Error fetching client products", error);
          console.error(error);
        }
      );
    },
    getProductSegments: function () {
      const hostUrlv2 = this.$config.aimsAPIv2;

      this.$http.get(`${hostUrlv2}segments`).then(
        (response) => {
          this.segments = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching product segments", error);
        }
      );
    },
    viewSale: function (service, sale) {
      const productRoute = this.getProductDefinition(service.productDefinition);

      if (productRoute == -1 && this.userContainsPermission(["STAFF"])) {
        window.open(
          `https://staff.adept.co.za/portal/staff#!sale.LIST/saleNumber=${sale.saleNumber}`
        );
      } else if (
        productRoute == -1 &&
        this.userContainsPermission(["CLIENT"])
      ) {
        this.$swal({
          title: "Service currently not accessible",
          text: "You'll soon be able to view this service. Sorry for the inconvenience.",
          type: "info",
          showCancelButton: true,
          cancelButtonText: "Back",
          cancelButtonColor: "#d33",
          showConfirmButton: false,
        });
      } else {
        this.$router.push({
          name: productRoute.routeName,
          params: {
            saleNumber: sale.saleNumber,
          },
        });
      }
    },
    normalizeString(str) {
      return str.split(" ").join("").toLowerCase();
    },
    isProductMatchingSearch(sale) {
      const matchingProduct = this.clientProducts.find(
        product => product.productNumber === sale.productNumber
      );

      if (matchingProduct &&
          matchingProduct.description.toLowerCase()
          .includes(this.serviceStore.searchTerm.toLowerCase())
      ) {
        return true;
      }
      return false;
    },
    showSaleStatusBadge: function (status) {
      switch (status) {
        case "Inactive":
          return "badge badge-deactivated text-white";
        case "Suspended":
          return "badge badge-warning text-white";
        default:
          return "";
      }
    },
    showSaleStatus: function (status) {
      switch (status) {
        case "Inactive":
          return "Inactive";
        case "Suspended":
          return "Suspended";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  .card-header {
    padding: 1em;
    background-color: #3a3f51;
    color: #ffffff;
  }
  .card-body {
    padding: 0 0 1em 0;
  }
}

.border-dark-grey {
  border: 1px solid #bebebe;
  border-radius: 3px;
}

.services-container {
  overflow-y: scroll;
}

@media only screen and (max-width: 1900px) {
  .services-container {
    max-height: 57vh;
  }
}

@media only screen and (min-width: 1920px) {
  .services-container {
    max-height: 66vh;
  }
}
</style>
